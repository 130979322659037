import type * as Types from '@hotelplan/supergraph-api';

import type { DocumentNode } from 'graphql';
import { FdrAirportFragmentDoc } from './fdr-airport.generated';
export type FdrFlightFragment = {
  __typename?: 'FdrFlight';
  duration: number;
  overnight: boolean;
  baggageInfo?: Array<string> | null;
  segments: Array<{
    __typename?: 'FdrFlightSegment';
    flightNumber: string;
    flightClass: Types.FdrFlightClass;
    duration: number;
    arrivalDate: any;
    arrivalTime: any;
    baggageIncluded: boolean;
    baggageInfo?: Array<string> | null;
    departureDate: any;
    departureTime: any;
    airportWaiting?: number | null;
    airline: { __typename?: 'FdrAirline'; iata: string; name: string };
    arrivalAirport: {
      __typename?: 'FdrAirport';
      name: string;
      isGroup: boolean;
      iata: string;
      codes?: Array<string> | null;
      coordinates?: {
        __typename?: 'FdrCoordinates';
        longitude: number;
        latitude: number;
      } | null;
    };
    departureAirport: {
      __typename?: 'FdrAirport';
      name: string;
      isGroup: boolean;
      iata: string;
      codes?: Array<string> | null;
      coordinates?: {
        __typename?: 'FdrCoordinates';
        longitude: number;
        latitude: number;
      } | null;
    };
  }>;
};

export type FdrFlightSegmentFragment = {
  __typename?: 'FdrFlightSegment';
  flightNumber: string;
  flightClass: Types.FdrFlightClass;
  duration: number;
  arrivalDate: any;
  arrivalTime: any;
  baggageIncluded: boolean;
  baggageInfo?: Array<string> | null;
  departureDate: any;
  departureTime: any;
  airportWaiting?: number | null;
  airline: { __typename?: 'FdrAirline'; iata: string; name: string };
  arrivalAirport: {
    __typename?: 'FdrAirport';
    name: string;
    isGroup: boolean;
    iata: string;
    codes?: Array<string> | null;
    coordinates?: {
      __typename?: 'FdrCoordinates';
      longitude: number;
      latitude: number;
    } | null;
  };
  departureAirport: {
    __typename?: 'FdrAirport';
    name: string;
    isGroup: boolean;
    iata: string;
    codes?: Array<string> | null;
    coordinates?: {
      __typename?: 'FdrCoordinates';
      longitude: number;
      latitude: number;
    } | null;
  };
};

export const FdrFlightSegmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fdrFlightSegment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FdrFlightSegment' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'flightNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'flightClass' } },
          { kind: 'Field', name: { kind: 'Name', value: 'duration' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'airline' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'iata' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'arrivalDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'arrivalTime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'baggageIncluded' } },
          { kind: 'Field', name: { kind: 'Name', value: 'baggageInfo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'departureDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'departureTime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'airportWaiting' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'arrivalAirport' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrAirport' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'departureAirport' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrAirport' },
                },
              ],
            },
          },
        ],
      },
    },
    ...FdrAirportFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
export const FdrFlightFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fdrFlight' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FdrFlight' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'duration' } },
          { kind: 'Field', name: { kind: 'Name', value: 'overnight' } },
          { kind: 'Field', name: { kind: 'Name', value: 'baggageInfo' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'segments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrFlightSegment' },
                },
              ],
            },
          },
        ],
      },
    },
    ...FdrFlightSegmentFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
