import React from 'react';
import styled from 'styled-components';
import { PlaceholderLoader } from '@hotelplan/components.common.placeholder-loader';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import { FdrRecommendationItemSkeleton } from './fdr-recommendation-item.skeleton';

export const FdrGroupTitleSkeleton: React.FC<{
  className?: string;
  uid: string;
}> = ({ className, uid }) => {
  const { mobile } = useDeviceType();

  return (
    <div className={className}>
      <PlaceholderLoader
        uid={uid}
        width={mobile ? 327 : 1170}
        height={mobile ? 31 : 49}
        style={{ width: '100%', height: mobile ? '31px' : '49px' }}
      >
        {!mobile && (
          <>
            <rect width="1170" height="1" />
            <rect y="22" width="288" height="27" rx="10" />
          </>
        )}

        {mobile && (
          <>
            <rect width="327" height="1" transform="matrix(1 0 0 -1 0 1)" />
            <rect y="11" width="182" height="20" rx="10" />
          </>
        )}
      </PlaceholderLoader>
    </div>
  );
};

const FdrSkeletonWrapper = styled.figure(
  sx2CssThemeFn({
    display: ['flex', `grid`],
    gridTemplateColumns: [null, `repeat(3, 1fr)`],
    gap: [3, '35px 30px'],
    flexWrap: 'wrap',
    px: [4, 6],
    mx: [0, 'auto'],
    maxWidth: [null, '1280px'],
    mb: '50px',
  })
);

export const FdrTitleSkeleton = styled(FdrGroupTitleSkeleton)(
  sx2CssThemeFn({
    gridColumn: [null, `span 3`],
  })
);

const FdrListItemSkeleton = styled(FdrRecommendationItemSkeleton)({
  width: '100%',
});

export const FdrRecommendationListSkeleton: React.FC<{
  className?: string;
  hasTitle?: boolean;
}> = ({ className, hasTitle }) => {
  const { mobile } = useDeviceType();

  return (
    <FdrSkeletonWrapper className={className} data-id="recommendation-skeleton">
      {hasTitle && <FdrTitleSkeleton uid="RecommendationListSkeleton_title" />}
      {!mobile && (
        <>
          <FdrListItemSkeleton />
          <FdrListItemSkeleton />
          <FdrListItemSkeleton />
        </>
      )}
      {mobile && <FdrListItemSkeleton />}
    </FdrSkeletonWrapper>
  );
};
