import type * as Types from '@hotelplan/supergraph-api';

import type { DocumentNode } from 'graphql';
export type FdrOfferRoomFragment = {
  __typename?: 'FdrProductOfferRoom';
  adults: number;
  childrenDobs?: Array<any> | null;
  mealDescription: string;
  roomDescription: string;
  bookingCode: string;
  mealType: string;
  roomType: string;
};

export const FdrOfferRoomFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fdrOfferRoom' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FdrProductOfferRoom' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'adults' } },
          { kind: 'Field', name: { kind: 'Name', value: 'childrenDobs' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mealDescription' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roomDescription' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bookingCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mealType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roomType' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
