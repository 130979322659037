import type * as Types from '@hotelplan/supergraph-api';

import type { DocumentNode } from 'graphql';
export type FdrPriceFragment = {
  __typename?: 'FdrPrice';
  value: number;
  currency: string;
};

export const FdrPriceFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fdrPrice' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FdrPrice' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
